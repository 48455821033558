#logo {
    transition: all 0.3s;
}

#logo:hover {
    filter: drop-shadow(2px 4px 6px black);
}

.required::after {
    content: " *";
    color: red;
}

.hover-darken:hover{
    filter: brightness(85%);
}
.hover-grow:hover{
    transform: scale(1.3);
    transition: all 0.2s ease-in-out;
}

.dropdown-toggle::after {
    display: none !important;
}

.accordion-button {
    color: #ffffff;
    background-color: #14561d;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #26a036;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.bg-hover-3a4e1e,
.bg-hover-dark,
.text-unauthorized {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.bg-hover-3a4e1e:hover {
    background-color: #3a4e1e;
}

.bg-hover-dark:hover {
    filter: brightness(85%);
    /* transform: scale(102%, 102%); */
}

.text-unauthorized:hover span {
    display: none;
}

.text-unauthorized:hover:after {
    content: "Unauthorized";
}

.bg-164d9d {
    background-color: #164d9d;
}

.scrollbar-custom::-webkit-scrollbar {
    width: 2px;
}

.scrollbar-custom:hover::-webkit-scrollbar {
    width: 10px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #79a734;
}

.new-stamp {
    position: relative;
}

.new-stamp:after {
    content: "";
    width: 130px;
    height: 130px;
    background: url("/public/newly-launched-rubber-grunge-stamp-seal.png") center;
    background-size: cover;
    top: -40px;
    left: -40px;
    position: absolute;
    display: inline-block;
    color: unset;
}

.heartbeat {
    display: block;
    
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

.my-modal .modal-content {
    background: #F7F8F2;
    color: black;

    -webkit-box-shadow: 0px 0px 75px -20px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 75px -20px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 75px -20px rgba(255, 255, 255, 1);

    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

.mt-70{
    margin-top: 70px;
}

.mb-70{
    margin-bottom: 70px;
}

.vertical-timeline {
   width: 100%;
   position: relative;
   padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
   content: '';
   position: absolute;
   top: 0;
   left: 67px;
   height: 100%;
   width: 4px;
   background: #e9ecef;
   border-radius: .25rem;
}

.vertical-timeline-element {
   position: relative;
   margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
   visibility: visible;
   animation: cd-bounce-1 .8s;
}
.vertical-timeline-element-icon {
   position: absolute;
   top: 0;
   left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
   box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
   width: 18px;
   height: 18px;
   position: relative;
}
.badge:empty {
   display: none;
}


.badge-dot-xl::before {
   content: '';
   width: 10px;
   height: 10px;
   border-radius: .25rem;
   position: absolute;
   left: 50%;
   top: 50%;
   margin: -5px 0 0 -5px;
   background: #fff;
}

.vertical-timeline-element-content {
   position: relative;
   margin-left: 90px;
   font-size: .8rem;
}

.vertical-timeline-element-content .timeline-title {
   font-size: .8rem;
   text-transform: uppercase;
   margin: 0 0 .5rem;
   padding: 2px 0 0;
   font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
   display: block;
   position: absolute;
   left: -90px;
   top: 0;
   padding-right: 10px;
   text-align: right;
   color: #adb5bd;
   font-size: .7619rem;
   white-space: nowrap;
}

.vertical-timeline-element-content:after {
   content: "";
   display: table;
   clear: both;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}



/* ----------------vertical range slider css---------------------- */
/* .hslider {
    writing-mode: tb;
    height: 300px;
    transform: rotate(180deg);

    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 10px;
}

.hslider::-webkit-slider-runnable-track {
    height: 40px;
    border-radius: 10px;
    background-color: #e5e5e5;
}
.hslider::-moz-range-track {
    height: 40px;
    border-radius: 10px;
    background-color: #e5e5e5;
}
.hslider:disabled::-webkit-slider-runnable-track {
    background-color: #606060;
}
.hslider:disabled::-moz-range-track {
    background-color: #606060;
}

.hslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 40px;
    background-color: #fff;
    border-radius: 50px;
    border: 3px solid rgb(61, 85, 244);
    box-shadow: 0 -408px 0 400px rgb(61, 85, 244);
}
.hslider::-moz-range-thumb {
    height: 20px;
    width: 40px;
    background-color: #fff;
    border-radius: 50px;
    border: 3px solid rgb(61, 85, 244);
    box-shadow: 0 -408px 400px 0 rgb(61, 85, 244);
}
.hslider:disabled::-webkit-slider-thumb {
    background-color: #606060;
    border: 3px solid #fff;
    box-shadow: 0 -408px 0 400px #606060;
}
.hslider:disabled::-moz-range-thumb {
    background-color: #606060;
    border: 3px solid #fff;
    box-shadow: 0 -408px 400px 0 #606060;
} */
/* ----------------vertical range slider css end---------------------- */